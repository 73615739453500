const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve({ file64: reader.result, orig: file });
    reader.onerror = reject;
});

const addUploadCapabilities = requestHandler => (type, resource, params) => {
    if (type === 'CREATE' && resource === 'reports') {
        if (params.data.files && params.data.files.length) {
            const formerfiles = params.data.files.filter(p => !(p.rawFile instanceof File));
            const newfiles = params.data.files.filter(p => p.rawFile instanceof File);

            return Promise.all(newfiles.map(convertFileToBase64))
                .then(base64files => base64files.map(x => ({
                    src: x.file64,
                    file: x.orig
                })))
                .then(transformedNewfiles => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [...transformedNewfiles, ...formerfiles],
                    },
                }));
        }
    }

    return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
