import * as React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import ReportsIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import { Admin, Resource, Login } from 'react-admin';
import { restClient, authClient } from 'ra-data-feathers';
import feathers from "@feathersjs/client";
import auth from "@feathersjs/authentication-client";
import { createBrowserHistory as createHistory } from 'history';
import createRealtimeSaga from "./realtime";
import { ReportsList, ReportsCreate, ReportsShow } from './reports';
import { UserList, UserShow } from './users';
import Dashboard from './Dashboard';
import addUploadCapabilities from './upload';
import VerifySignup from './verify';
import config from './config';
import authC from './auth';
import SignUp from './signup';

const authOptions = { jwtStrategy: 'jwt', storage: window.localStorage };
const feathersClient = feathers().configure(feathers.rest(config.host).fetch(window.fetch.bind(window))).configure(auth(authOptions));
const MyLoginPage = () => <Login backgroundImage="/bg.jpg" />;

const customRoutes = [
    <Route exact path="/verify/:token" component={VerifySignup} noLayout />,
    <Route exact path="/signup" component={SignUp} noLayout />,
];

const restClientOptions = {
    id: 'id', 
    usePatch: true 
};

const authClientOptions = {
    storageKey: 'feathers-jwt',
    authenticate: { 
      strategy: 'local', 
    },
    permissionsKey: 'permissions', 
    permissionsField: 'permissions', 
    passwordField: 'password', 
    usernameField: 'email', 
    logoutOnForbidden: true, 
}

const history = createHistory();

const dataProvider = restClient(feathersClient, restClientOptions);
const uploadCapableClient = addUploadCapabilities(dataProvider);


const realTimeSaga = createRealtimeSaga(dataProvider);

const App = () => (
    <Admin
        title='IP Reporter'
        dataProvider={uploadCapableClient}
        authProvider={authC(feathersClient, authClientOptions)}
        dashboard={Dashboard}
        history={history}
        customSagas={[realTimeSaga]}
        loginPage={MyLoginPage}
        customRoutes={customRoutes}
    >
    {permissions => [
        <Resource
            name="reports"
            icon={ReportsIcon}
            list={ReportsList}
            create={ReportsCreate}
            show={ReportsShow}
        />,
        permissions === 'admin' ? <Resource 
            name="users" 
            icon={UserIcon} 
            list={UserList} 
            show={UserShow}
        /> : null
    ]}

    </Admin>
);
export default App;
