import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

export default () => (
    <Card>
        <CardHeader title="Welcome to the Swift reporter!" />
        <CardContent>Please report the ips you do not like...</CardContent>
    </Card>
);
