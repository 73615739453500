import * as React from 'react';
import {
    Show,
    ShowButton,
    SimpleShowLayout,
    RichTextField,
    DateField,
    List,
    Create,
    Datagrid,
    FileField,
    TextField,
    FileInput,
    SelectInput,
    SimpleForm,
    NumberInput,
    TextInput,
    regex,
    required,
    Filter,
} from 'react-admin';

const ReportsFilter = props => (
    <Filter {...props}>
        <TextInput label="Search Guilty IP" source="guiltyIP" alwaysOn />
    </Filter>
);

export const ReportsList = props => (
    <List {...props} bulkActionButtons={false} filters={<ReportsFilter />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="guiltyIP" label="Guilty IP" />
            <TextField source="typeOfAttack" label="Type of Attack" />
            <TextField source="targetIP" label="Target IP" />
            <TextField source="targetDomain" label="Target Domain" />
            <TextField source="targetPort" label="Target Port" />
            <TextField source="reporter" label="Reporter's email address" />
            <DateField source="createdAt" />
            <ShowButton />
        </Datagrid>
    </List>
);

const ReportsTitle = ({ record }) => {
    return <span>Reports {record ? `"${record.guiltyIP}"` : ''}</span>;
};

const validateIP = regex(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Must be a valid IP address.');
const validateDomain = regex(/^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/, 'Must be a valid domain name.');
export const ReportsCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="guiltyIP" label="Guilty IP" validate={[required(), validateIP]}/>
            <SelectInput source="typeOfAttack" label="Type of Attack" choices={[
                { id: 'DDOS', name: 'DDOS' },
                { id: 'Phishing', name: 'Phishing' },
                { id: 'Brute Force', name: 'Brute Force' },
                { id: 'Email', name: 'Email' },
                { id: 'Malware', name: 'Malware' },
                { id: 'Man-in-the-Middle', name: 'Man-in-the-Middle' },
                { id: 'SQL Injection', name: 'SQL Injection' },
                { id: 'DNS Tunnelling', name: 'DNS Tunnelling' },
                { id: 'Session Hijacking', name: 'Session Hijacking' },
                { id: 'URL Interpretation', name: 'URL Interpretation' },
                { id: 'IP Spoofing', name: 'IP Spoofing' },
                { id: 'Cross-site Scripting XSS', name: 'Cross-site Scripting XSS' },
                { id: 'Other', name: 'Other' },
            ]} validate={[required()]}/>
            <TextInput source="targetIP" label="Target IP" validate={[required(), validateIP]}/>
            <TextInput source="targetDomain" label="Target Domain" validate={[validateDomain]}/>
            <NumberInput source="targetPort" label="Target Port" validate={[required()]}/>
            <TextInput multiline source="description" validate={[required()]}/>
            <FileInput multiple source="files" label="Documentation (Please attach all materials that show proof of the attack, this includes documents like log files, screenshots and any other data that can be used to back this claim)">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
);

export const ReportsShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
            <TextField source="guiltyIP" label="Guilty IP" />
            <TextField source="typeOfAttack" label="Type of Attack" />
            <TextField source="targetIP" label="Target IP" />
            <TextField source="targetDomain" label="Target Domain" />
            <TextField source="targetPort" label="Target Port" />
            <TextField source="reporter" label="Reporter's email address" />
            <RichTextField source="description" />
            <FileField label="Documentation (All materials that show proof of the attack, this includes documents like log files, screenshots and any other data that can be used to back this claim)" source="files" src="link" title="Link" />
            <DateField source="createdAt" />
        </SimpleShowLayout>
    </Show>
);
