import * as React from 'react';
import { Redirect } from "react-router-dom";
import config from './config';
import axios from 'axios';

class VerifySignup extends React.Component {

    state = { redirect: 0 };
    componentDidMount() {
        const { token } = this.props.match.params
        let data = {
            "action": "verifySignupLong",
            "value": token
        };
        axios.post(config.host + '/authManagement', data).then(this.setState({ redirect: 1 }));
      }
    
    render() {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }

        return(<p>Loading...</p>)
    }

}

export default VerifySignup