import * as React from 'react';
import { List, Datagrid, EmailField, TextField, ShowButton, Show, SimpleShowLayout, DateField, ImageField } from 'react-admin';

export const UserList = props => {
    return (
        <List {...props} title="All users" {...props}>
                    <Datagrid>
                        <TextField source="id" />
                        <TextField source="firstName" />
                        <TextField source="lastName" />
                        <EmailField source="email" />
                        <ShowButton />
                    </Datagrid>
        </List>
    );
};

export const PostList = props => (
    <List {...props} filters={<PostFilter />}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField label="User" source="userId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="title" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);


export const UserShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="email" />
            <TextField source="mobileNumber" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />

        </SimpleShowLayout>
    </Show>
);
